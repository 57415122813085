<template>
  <div class="wrapper">
    <video ref="videoPlayer" class="video-js full"></video>
    <AdContainer v-if="loaded && options.overlay" :adTagUrl="options.overlay" :videoElement="$refs.videoPlayer" />
  </div>
</template>
<script>
import videojs from "video.js";
import "videojs-contrib-ads";
import "videojs-ima";
import AdContainer from "./AdContainer.vue";

export default {
  name: "VideoPlayer",
  components: { AdContainer },
  props: {
    options: {
      type: Object,
    },
  },
  data() {
    return {
      player: null,
      loaded: false,
    };
  },
  watch: {
    options() {
      this.setupPlayer();
    },
  },
  mounted() {
    this.setupPlayer();
  },
  beforeUnmount() {
    if (this.player) {
      this.player.dispose();
    }
  },
  methods: {
    setupPlayer() {
      //   console.log("setupPlayer");
      if (!this.$refs.videoPlayer || !this.options) return;

      if (this.player) {
        this.player.dispose();
      }
      this.player = videojs(this.$refs.videoPlayer, this.options, () => {
        // this.player.log("onPlayerReady", this);
        this.loaded = true;
      });
      this.player.on("play", this.onPlay);

      if (this.options.preroll) {
        this.player.ima({
          adTagUrl: this.options.preroll,
        });
      }
    },

    onPlay() {
      //   console.log("onPlayer", e);
    },
  },
};
</script>
<style scoped>
@import "video.js/dist/video-js.css";

.wrapper {
  width: 100%;
  height: 100%;
}

.video-js.full {
  width: 100%;
  height: 100%;
}
</style>
