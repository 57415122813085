<template>
  <div v-if="error">
    {{ error }}
  </div>
  <VideoPlayer v-else :options="videoOptions" />
</template>

<script>
const getQueryStringObject = () => {
  var a = window.location.search.substring(1).split("&");
  if (a == "") return {};
  var b = {};
  for (var i = 0; i < a.length; ++i) {
    var p = a[i].split("=", 2);
    if (p.length == 1) b[p[0]] = "";
    else b[p[0]] = decodeURIComponent(p[1].replace(/\+/g, " "));
  }
  return b;
};

import VideoPlayer from "./components/VideoPlayer.vue";
import axios from "axios";

export default {
  name: "App",
  components: {
    VideoPlayer,
  },
  data() {
    const params = getQueryStringObject();
    if (params.w) {
      return {
        params,
        error: null,
        videoOptions: null,
      };
    } else {
      return {
        error: "올바르지 않습니다.",
      };
    }
  },
  async created() {
    console.log(`${process.env.VUE_APP_API}/api/public/v/${this.params.w}`);
    const { data } = await axios.get(`${process.env.VUE_APP_API}/api/public/v/${this.params.w}`);
    if (data.data) {
      this.videoOptions = data.data;
    }
  },
};
</script>
<style>
@import "@/assets/reset.css";
@import "@/assets/app.css";

#app {
  height: 100%;
}
</style>
