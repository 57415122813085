<template>
  <div v-show="!endAds" class="ads">
    <div ref="adContainer" class="ad-container"></div>
  </div>
</template>
<script>
let adDisplayContainer;
let adsLoader;
let adsManager;

export default {
  props: {
    videoElement: Object,
    adTagUrl: String,
  },
  data() {
    return {
      //   adDisplayContainer: null,
      //   adsLoader: null,
      //   adsManager: null,
      adsLoaded: false,
      endAds: false,
    };
  },
  mounted() {
    this.videoElement.addEventListener("play", (event) => {
      this.loadAds(event);
    });
    this.videoElement.addEventListener("ended", () => {
      adsLoader.contentComplete();
    });

    adDisplayContainer = new window.google.ima.AdDisplayContainer(this.$refs.adContainer, this.videoElement);
    adsLoader = new window.google.ima.AdsLoader(adDisplayContainer);

    adsLoader.addEventListener(window.google.ima.AdsManagerLoadedEvent.Type.ADS_MANAGER_LOADED, this.onAdsManagerLoaded, false);
    adsLoader.addEventListener(window.google.ima.AdErrorEvent.Type.AD_ERROR, this.onAdError, false);

    this.requestAds();

    window.addEventListener("resize", this.onResize);
  },
  methods: {
    onResize() {
      console.log("window resized");
      if (adsManager) {
        const width = this.videoElement.clientWidth;
        const height = this.videoElement.clientHeight;
        adsManager.resize(width, height, window.google.ima.ViewMode.NORMAL);
      }
    },
    onAdsManagerLoaded(adsManagerLoadedEvent) {
      console.log("onAdsManagerLoaded");
      this.adsLoaded = true;
      adsManager = adsManagerLoadedEvent.getAdsManager(this.videoElement);

      adsManager.addEventListener(window.google.ima.AdErrorEvent.Type.AD_ERROR, this.onAdError);
      adsManager.addEventListener(window.google.ima.AdEvent.Type.CONTENT_PAUSE_REQUESTED, this.onContentPauseRequested);
      adsManager.addEventListener(window.google.ima.AdEvent.Type.CONTENT_RESUME_REQUESTED, this.onContentResumeRequested);
      adsManager.addEventListener(window.google.ima.AdEvent.Type.LOADED, this.onAdLoaded);
      adsManager.addEventListener(window.google.ima.AdEvent.Type.SKIPPED, this.onAdSkipped);

      this.loadAds();
      /*
      AD_BREAK_FETCH_ERROR: "adBreakFetchError"
    AD_BREAK_READY: "adBreakReady"
    AD_BUFFERING: "adBuffering"
    AD_CAN_PLAY: "adCanPlay"
    AD_METADATA: "adMetadata"
    AD_PROGRESS:"adProgress"
    ALL_ADS_COMPLETED: "allAdsCompleted"
    Bg: "externalActivityEvent"
    CLICK: "click"
    COMPLETE: "complete"
    CONTENT_PAUSE_REQUESTED: "contentPauseRequested"
    CONTENT_RESUME_REQUESTED: "contentResumeRequested"
    Ch: "trackingUrlPinged"
    DURATION_CHANGE: "durationChange"
    EXPANDED_CHANGED: "expandedChanged"
    Ed: "measurable_impression"
    Ee: "loadStart"
    FIRST_QUARTILE: "firstQuartile"
    Fh: "userRecall"
    Gh: "video_card_endcap_collapse"
    He: "overlay_resize"
    Hh: "video_card_endcap_dismiss"
    IMPRESSION: "impression"
    INTERACTION: "interaction"
    Ie: "overlay_unmeasurable_impression"
    Ih: "video_card_endcap_impression"
    Je: "overlay_unviewable_impression"
    Ke: "overlay_viewable_end_of_session_impression"
    LINEAR_CHANGED: "linearChanged"
    LOADED: "loaded"
    LOG: "log"
    Le: "overlay_viewable_immediate_impression"
    MIDPOINT: "midpoint"
    PAUSED: "pause"
    Pe: "skipShown"
    RESUMED: "resume"
    SKIPPABLE_STATE_CHANGED: "skippableStateChanged"
    SKIPPED: "skip"
    STARTED: "start"
    THIRD_QUARTILE: "thirdQuartile"
    USER_CLOSE: "userClose"
    VIDEO_CLICKED: "videoClicked"
    VIDEO_ICON_CLICKED: "videoIconClicked"
    VIEWABLE_IMPRESSION: "viewable_impression"
    VOLUME_CHANGED: "volumeChange"
    VOLUME_MUTED: "mute"
    eh: "mediaUrlPinged"
    gh: "navigationRequested"
    ig: "adStarted"
    nh: "prefetched"
    pg: "companionBackfill"
    qg: "companionClick"
    rg: "companionImpression"
    sg: "companionInitialized"
    yd: "engagedView"
    zd: "fully_viewable_audible_half_duration_impression"
*/

      //   console.log("ads manager loaded", adsManager);
    },
    onAdError(adErrorEvent) {
      console.log(adErrorEvent.getError());
      if (adsManager) {
        adsManager.destroy();
        adsManager = null;
      }
      this.endAds = true;
    },
    onAdSkipped() {
      console.log("ad skipped");
    },
    onContentPauseRequested() {
      this.videoElement.pause();
    },
    onContentResumeRequested() {
      this.videoElement.play();
    },
    onAdLoaded(adEvent) {
      const ad = adEvent.getAd();
      if (!ad.isLinear()) {
        this.videoElement.play();
      }
    },
    requestAds() {
      const adsRequest = new window.google.ima.AdsRequest();
      adsRequest.adTagUrl = this.adTagUrl;

      // Specify the linear and nonlinear slot sizes. This helps the SDK to
      // select the correct creative if multiple are returned.
      adsRequest.linearAdSlotWidth = this.videoElement.clientWidth;
      adsRequest.linearAdSlotHeight = this.videoElement.clientHeight;
      adsRequest.nonLinearAdSlotWidth = this.videoElement.clientWidth;
      adsRequest.nonLinearAdSlotHeight = this.videoElement.clientHeight / 3;

      // Pass the request to the adsLoader to request ads
      adsLoader.requestAds(adsRequest);
    },
    loadAds(event) {
      if (!this.adsLoaded || this.videoElement.paused) {
        return;
      }

      if (event) {
        event.preventDefault();
      }

      // this.videoElement.load();
      adDisplayContainer.initialize();

      var width = this.videoElement.clientWidth;
      var height = this.videoElement.clientHeight;
      height = 100;
      try {
        adsManager.init(width, height, window.google.ima.ViewMode.NORMAL);
        adsManager.start();
      } catch (adError) {
        // Play the video without ads, if an error occurs
        console.log("AdsManager could not be started", adError);
        // this.videoElement.play();
      }
    },
  },
};
</script>
<style scoped>
.ads {
  position: absolute;
  display: block;
  left: 0px;
  width: 100%;
  height: 100px;
  z-index: 30;
  bottom: 60px;
}
.ad-container {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100px;
}
</style>
